import { Box } from "@chakra-ui/react";
import React from "react";

const PrivacyPolicy = () => {
  return (
    <>
      <Box display="flex" flexDirection="column" padding="30px">
        <div>
          <h1
            style={{
              marginTop: "8vh",
              textAlign: "center",

              fontSize: "xx-large",
              fontWeight: "bolder",
            }}
          >
            Privacy Policy
          </h1>
          <h4>1. GENERAL</h4>
          <p style={{ fontSize: "large", fontWeight: "400" }}>
            a) We value the safety and privacy of your data. We have formulated
            this Privacy Policy to make the users understand our process of
            collecting information and parameters for non-disclosure. This
            document is an electronic record in terms of the Information
            Technology Act, 2000 and rules there under as applicable and the
            amended provisions pertaining to electronic records in various
            statutes as amended by the Information Technology Act, 2000. This
            electronic record is generated by a computer system and does not
            require any physical or digital signatures.
            <br /> b) This document is published in accordance with the
            provisions of Rule 3 (1) of the Information Technology (Intermediary
            Guidelines and Digital Media Ethics Code) Rules, 2021 that require
            publishing the Rules and Regulations, Privacy Policy and Terms of
            Use for access or usage https://blazify.tech.
            <br /> c) The domain name https://blazify.tech 1 ("Website/Mobile
            Application''), is owned and operated by Blazify Private Limited
            (“Company”) a company registered under the laws of the Republic of
            India having its registered office at C/O Runa Laila, Netaji Park,
            Islampur Murshidabad, West Bengal - 742304. Where such expression
            shall, unless repugnant to the context thereof, be deemed to include
            its respective representatives, administrators, employees, officers,
            agents and their successors and assignees. d) For the purpose of
            these Terms of Use (“Terms”), wherever the context so requiresi. The
            term ‘You’ &‘User’ shall mean any legal person or entity accessing
            or using the services provided on this Website/Mobile Application,
            who is competent to enter into binding contracts, as per the
            provisions of the Indian Contract Act, 1872; ii. The terms ‘We’,
            ‘Us’ & ‘Our’ shall mean the Website/Mobile Application and/or the
            Company, as the context so requires. The terms ‘Party’&‘Parties’
            shall respectively be used to refer to the User and the Company
            individually and collectively, as the context so requires.
            <br /> e) The headings of each section in this Policy are only for
            the purpose of organizing the various provisions under this Policy
            in an orderly manner, and shall not be used by either Party to
            interpret the provisions contained herein in any manner. Further, it
            is specifically agreed to by the Parties that the headings shall
            have no legal or contractual value.
            <br /> f) The use of the Website/Application by the User is solely
            governed by this Policy as well as the Terms of Use of the
            Website/Application(“Terms”, available at https://blazify.tech) and
            any modifications or amendments made thereto by the company from
            time to time, at its sole discretion. Visiting the home page of the
            Website/Application and/or using any of the services provided on the
            Website/Application shall be deemed to signify the User’s
            unequivocal acceptance of this Policy and the aforementioned Terms,
            and the User expressly agrees to be bound by the same. The User
            expressly agrees and acknowledges that the Terms and Policy are
            co-terminus and that the expiry/termination of either one will lead
            to the termination of the other.
            <br /> g) The User unequivocally agrees that this Policy and the
            aforementioned Terms constitute a legally binding agreement between
            the User and the Website/Mobile Application, and that the User shall
            be subject to the rules, guidelines, policies, terms, and conditions
            applicable to any service that is provided by the Website/Mobile
            Application, and that the same shall be deemed to be incorporated
            into the Terms, and shall be treated as part and parcel of the same.
            The User acknowledges and agrees that no signature or express act is
            required to make these Terms and the Policy binding on the User and
            that the User’s act of visiting any part of the Website constitutes
            the User’s full and final acceptance of the Policy and the
            aforementioned Terms.
            <br /> h) The Parties expressly agree that the company retains the
            sole and exclusive right to amend or modify the Policy and the
            aforementioned Terms without any prior permission or intimation to
            the User and the User expressly agrees that any such amendments or
            modifications shall come into effect immediately. The User must
            periodically check the Policy and Terms, and stay updated on their
            policies. <br />
            i) If the User continues to use the Website/Mobile Application
            following such a change, the User will be deemed to have consented
            to any amendments/modifications made to the Policy and Terms. In so
            far as the User complies with the Policy and Terms, he/she is
            granted a personal, non-exclusive, non-transferable, revocable,
            limited privilege to enter, access and use the Website/Mobile
            Application.
          </p>
          <h4>2. COLLECTION OF PERSONAL AND OTHER INFORMATION</h4>
          <p style={{ fontSize: "large", fontWeight: "400" }}>
            a) The User expressly agrees and acknowledges that the website
            collects and stores the User’s personal information, which is
            provided by the User from time to time on the Website/Mobile
            Application, including but not limited to the User’s username,
            password, email address, name, address, Service address,
            destination, age, date of birth, sex, nationality, etc., as well as
            any images or other information uploaded/submitted by the User on
            the Website. The User is aware that this information will be used by
            the Company/Website to provide services and/or products and features
            targeted at the User, that are most likely to meet the User’s needs,
            and also to customize and improve the Website/Mobile Application to
            make its users’ experiences safer and easier.
            <br /> b) The Company on a timely basis will evaluate the personal
            information of the User and keep it safe, accurate and up to date.
            If the User identifies any error in personal information or needs to
            make a change to that information, then the User can make it updated
            by contacting the Company via e-mail at rahaman.ronit@gmail.com{" "}
            <br />
            c) The User is aware that the Company/Website may automatically
            track certain information about the User based upon the User’s IP
            address, location and the User’s behaviour on the Website/Mobile
            Application and the User expressly consents to the same. The User is
            aware that this information is used to do internal research on user
            demographics, interests, and behaviour, to enable the
            Company/Website to better understand, and cater to the interests of
            its users. The User is expressly made aware that such information
            may include the URL that the User visited before accessing the
            Website, the URL which the User subsequently visits (whether or not
            these URLs form a part of the Website), the User’s computer & web
            browser information, the User’s IP address, etc.
            <br /> d) If the User chooses to avail services from the
            Website/Mobile Application, the User consents to allow the Website
            to collect information about the User’s buying behaviour and trends.
            <br /> e) If the User chooses to post messages, ads, promotions,
            reviews, or feedback anywhere on the Website/Mobile Application,
            including but not limited to message boards, chat rooms, other
            message areas, etc., the User is aware that any such information
            provided or uploaded will be collected and stored by the Company
            indefinitely, and that such retained information may be used to
            resolve disputes, provide customer support, troubleshoot problems,
            etc., and that such information, if requested, may be provided to
            judicial or governmental authorities of requisite jurisdiction, or
            otherwise used by the Company or Website as permitted by applicable
            laws.
            <br /> f) The User is aware that any information about the User is
            collected by the Website/Mobile Application, whether or not directly
            provided by the User to the Company or Website, including but not
            limited to personal correspondence such as emails or letters, and
            feedback from others.
            <br />
            g) The User is aware that while he/she can browse some sections of
            the Website without being a registered user, certain activities
            (such as availing a service) require the User to provide valid
            personal information to the Company or Website for registration. The
            User is aware that the contact information provided to the company
            or Website may be used to send the offers and promotions, whether or
            not based on the User’s previous services and interests, and the
            User hereby expressly consents to receive the same.
          </p>
          <h4>3. USER’S RESPONSIBILITIES</h4>
          <p style={{ fontSize: "large", fontWeight: "400" }}>
            It is a User's responsibility to provide correct information about
            its contact particulars. The company shall send emails or call at
            the email addresses/ phone numbers mentioned by a User at the time
            of registration. Therefore, if a user has inadvertently registered a
            wrong email id or phone number for correspondence, the Company
            offers a facility to cross-check information submitted at the time
            of registration or even correct the errors later. The company will
            not be liable for any incorrect information submitted by a user at
            any point in time on www.constroplus.com
          </p>
          <h4>4. COOKIES</h4>
          <p style={{ fontSize: "large", fontWeight: "400" }}>
            a) The User is aware that a ‘Cookie’ is a small piece of information
            stored by a web server on a web browser so it can later be traced
            back from that particular browser, and that cookies are useful for
            enabling the browser to remember information specific to a given
            user, including but not limited to a User’s login identification,
            password, etc. The User is aware that the Website places both
            permanent and temporary cookies in the User’s computer's hard drive
            and web browser, and does hereby expressly consent to the same.
            <br /> b) The User is further aware that the Company uses or might
            use data collection devices such as cookies on certain pages of
            https://blazify.tech to help analyse web page flow, measure
            promotional effectiveness, and promote trust and safety, and that
            certain features of the Website/Mobile Application are only
            available through the use of such cookies. While the User is free to
            decline the Website’s cookies if the User’s browser permits, the
            User may consequently be unable to use certain features on the
            website.
            <br /> c) Additionally, the User is aware that he/she might
            encounter ‘cookies’ or other similar devices on certain pages of the
            website that are placed by third parties or affiliates of the
            company. The User expressly agrees and acknowledges that the Company
            or Website does not control the use of such cookies/other devices by
            third parties, that the Company or Website is in no way responsible
            for the same and that the User assumes any risks in this regard.
          </p>
          <h4>5. DIVULGING/SHARING OF PERSONAL INFORMATION</h4>
          <p style={{ fontSize: "large", fontWeight: "400" }}>
            a) The User is aware that the Company may disclose personal
            information if required to do so by law or if the company in good
            faith believes that such disclosure is reasonably necessary to
            respond to subpoenas, court orders, or other legal processes. The
            Company may also disclose the User’s personal information to law
            enforcement offices, third-party rights owners, or other third
            parties if it believes that such disclosure is reasonably necessary
            to enforce the Terms or Policy; respond to claims that an
            advertisement, posting or other content violates the rights of a
            third party; or protect the rights, property or personal safety of
            its users, or the general public.
            <br /> b) The User is further aware that the Company and its
            affiliates may share some or all of the User’s personal information
            with other business entities should the Company (or its assets) plan
            to merge with, or be acquired by such business entity, or in the
            event of re-organization, amalgamation, or restructuring of the
            Company’s business. Such business entity or new entity will continue
            to be bound by the Terms and Policy, as may be amended from time to
            time.
            <br /> c) The User must be aware that the Company may provide some
            of the personal information of the User to third-party companies for
            their marketing of services or products. But such third-party
            companies cannot retain or use the User’s personal information more
            than necessary to provide the product, service, or information
            unless the User specifically grants permission to the Company for
            doing so.
            <br /> d) The Company may disclose the information if legally
            required to, pursuant to an order from a governmental entity or in
            good faith. We will disclose the information bases to: <br />●
            Confirm to company’s legal requirements or comply with legal
            process;
            <br /> ● protect our rights or property or our affiliated companies;{" "}
            <br />● prevent a crime or protect national security; or
            <br /> ● protect the personal safety of users or the public.
          </p>
          <h4>6. THIRD-PARTY ADVERTISEMENTS / PROMOTIONS</h4>
          <p style={{ fontSize: "large", fontWeight: "400" }}>
            The User is aware that the Company uses third-party advertising
            companies to serve ads to the users of the Website/Mobile
            Application. The User is aware that these companies may use
            information relating to the User’s visits to the Website/Mobile
            Application and other websites to provide customised advertisements
            to the User. Furthermore, the Website may contain links to other
            websites that may collect personally identifiable information about
            the User. The Company is not responsible for the privacy practices
            or the content of any of the aforementioned linked websites, and the
            User expressly acknowledges the same and agrees that any risks
            associated will be borne entirely by the User.
          </p>
          <h4>7. USER’S CONSENT</h4>
          <p style={{ fontSize: "large", fontWeight: "400" }}>
            By using the Website/Mobile Application or by providing information
            to the Company through the Website/Mobile Application, the User
            consents to the collection and use of the information disclosed by
            the User on the Website in accordance with this Policy, including
            but not limited to the User’s consent the company sharing/divulging
            the User’s information, as per the terms contained hereinabove in
            clause 2 of the Policy.
          </p>
          <h4>8. GRIEVANCE OFFICER</h4>
          <p style={{ fontSize: "large", fontWeight: "400" }}>
            In case the User has any grievances in connection with the use of
            the Website, a user may write to the Grievance Redressal Officer
            (GRO). The GRO is authorized to attend to any user complaints or
            queries and resolve any matter within 15 days from the date of
            receipt of the complaint. In accordance with Information Technology
            Act 2000 and the rules made there under, the name and contact
            details of the Grievance Officer are provided below: <br />
            <br />
            <h5 style={{ display: "inline-block" }}>Name</h5> – Mr Habibur
            Rahaman
            <br />
            <br /> <h5 style={{ display: "inline-block" }}>Address</h5> – Vill -
            P.O + P.S - Islampur, Murshidabad, West Bengal - 742304
            <br />
            <br /> <h5 style={{ display: "inline-block" }}>Email</h5> –
            admin@blazify.tech
          </p>
          <h4>9. DISPUTE RESOLUTION AND JURISDICTION</h4>
          <p style={{ fontSize: "large", fontWeight: "400" }}>
            It is expressly agreed to by the Parties hereto that the formation,
            interpretation and performance of this Policy and any disputes
            arising here from will be resolved through a two-step Alternate
            Dispute Resolution (“ADR”) mechanism. It is further agreed to by the
            Parties that the contents of this Section shall survive even after
            the termination or expiry of the Policy and/or Terms.
            <br /> a) Mediation: In case of any dispute between the parties, the
            Parties will attempt to resolve the same amicably amongst
            themselves, to the mutual satisfaction of both Parties. If the
            Parties are unable to reach such an amicable solution within thirty
            (30) days of one Party communicating the existence of a dispute to
            the other Party, the dispute will be resolved by arbitration, as
            detailed herein below;
            <br />
            b) Arbitration. If the Parties are unable to amicably resolve a
            dispute by mediation, said dispute will be referred to arbitration
            by a sole arbitrator to be appointed by the Company, and the award
            passed by such sole arbitrator will be valid and binding on both 16
            Parties. The Parties shall bear their costs for the proceedings,
            although the sole arbitrator may, in his/her sole discretion, direct
            either Party to bear the entire cost of the proceedings. The
            arbitration shall be conducted in English, and the seat of
            Arbitration shall be at Berhampore, Murshidabad, West Bengal, India.
          </p>
        </div>
      </Box>
    </>
  );
};

export default PrivacyPolicy;
