import { Box } from "@chakra-ui/react";
import React from "react";

const TermsOfService = () => {
  return (
    <>
      <Box display="flex" flexDirection="column" padding="30px">
        <div>
          <h1
            style={{
              marginTop: "8vh",
              textAlign: "center",

              fontSize: "xx-large",
              fontWeight: "bolder",
            }}
          >
            Terms Of Use
          </h1>
          <h4>1. GENERAL</h4>
          <p style={{ fontSize: "large", fontWeight: "400" }}>
            a) This document is an electronic record in terms of the Information
            Technology Act, 2000 and rules there under as applicable and the
            amended provisions pertaining to electronic records in various
            statutes as amended by the Information Technology Act, 2000. This
            electronic record is generated by a computer system and does not
            require any physical or digital signatures. <br />
            b) This document is published in accordance with the provisions of
            Rule 3 (1) of the Information Technology (Intermediary Guidelines
            and Digital Media Ethics Code) Rules, 2021 that require publishing
            the Rules and Regulations, Privacy Policy and Terms of Use for
            access or usage https://blazify.tech.
            <br /> c) The domain name https://blazify.tech ("Website"),
            including its online services and/or products, such as the use of
            software on the Website and information, music, audio, videos, text,
            graphics, images and information obtained from service providers and
            any other material contained on the Website is owned and operated by
            Blazify Private Limited, a company registered under the laws of
            Republic of India having its registered office at C/O Runa Laila,
            Netaji Park, Islampur Murshidabad, West Bengal - 742304, where such
            expression shall, unless repugnant to the context thereof, be deemed
            to include its respective representatives, administrators,
            employees, directors, officers, agents and their successors and
            assigns.
            <br /> d) For the purpose of these Terms of Use (“Terms”), wherever
            the context so requires,
            <br /> i) The website https://blazify.tech means and includes the
            Website/Mobile Application owned and managed by the Company. <br />
            ii) The term ‘You’ or “User” shall mean any legal person or entity
            accessing using this Website for services provided or registered on
            this Website, who is competent to enter into binding contracts, as
            per the provisions of the Indian Contract Act, 1872;
            <br /> iii) The terms ‘We’, ‘Us’ &‘Our’ shall mean the Website,
            Mobile Application and/or the Company, as the context so requires.
            <br /> iv) The terms ‘Party’&‘Parties’ shall respectively be used to
            refer to the User and the Company individually and collectively, as
            the context so requires.
            <br /> e) The headings of each section in these Terms are only for
            the purpose of organizing the various provisions under these Terms
            in an orderly manner, and shall not be used by either Party to
            interpret the provisions contained herein in any manner. Further, it
            is specifically agreed to by the Parties that the headings shall
            have no legal or contractual value.
            <br /> f) The use of the Website by the user is solely governed by
            these Terms as well as the Privacy Policy (“Policy”), available on
            the Website and any modifications or amendments made thereto by the
            Company from time to time, at its sole discretion. Visiting the home
            page of the Website/Mobile Application and/or using any of the
            services provided on the Website shall be deemed to signify the
            User’s unequivocal acceptance of these Terms and the aforementioned
            Policy, and the User expressly agrees to be bound by the same. The
            User expressly agrees and acknowledges that the Terms of Use and
            Policy are co-terminus and that the expiry/termination of either one
            will lead to the termination of the other, save as provided in
            Clause 4 hereunder.
            <br /> g) The User unequivocally agrees that these Terms and the
            aforementioned Policy constitute a legally binding agreement between
            the User and the Company, and that the User shall be subject to the
            rules, guidelines, policies, terms, and conditions applicable to any
            service that is provided by the Website, and that the same shall be
            deemed to be incorporated into these Terms, and shall be treated as
            part and parcel of the same. The User acknowledges and agrees that
            no signature or express act is required to make these Terms and the
            Policy binding on the User and that the User’s act of visiting any
            part of the Website constitutes the User’s full and final acceptance
            of these Terms and the aforementioned Policy. <br />
            h) The Company reserves the sole and exclusive right to amend or
            modify these Terms without any prior permission or intimation to the
            User, and the User expressly agrees that any such amendments or
            modifications shall come into effect immediately. The User must
            periodically check the terms and stay updated on its requirements
          </p>
          <h4>2. OUR SERVICES</h4>
          <p style={{ fontSize: "large", fontWeight: "400" }}>
            Blazify Private Limited is a company founded by two founders. Set up
            in 2022 in Berhampore, Murshidabad, West Bengal, we at Blazify
            through our website https://blazify.tech and our mobile application
            “Blazify” is a tech-based start-up company engaged and focussed in
            creating new websites, desktop and mobile applications for the
            day-to-day use by people who are unaware about the technological
            advancements in current world or those who are not well equipped
            with such technological advancements. Company intends to work
            together in the area of creation of websites and mobile applications
            for its beneficiaries and clients. The management concept of the
            Company is founded on professionalism, integrity, accountability,
            and quality service that guarantee the maximum return plus
            satisfaction to their clients, while maintaining highest standard of
            Digital Facilities. The Company provides a platform for potential
            users to hire professional from different fields like Mobile
            Development, Front-end & Back-end Development and to get the
            relevant work done efficiently and offers the same on competitive
            prices. At Blazify we aim to serve those clients who accept the
            existing policies of the Company and to help those who are unware
            but in need of technological proficiency in day to day life.
          </p>
          <h4>3. ELIGIBILITY</h4>
          <p style={{ fontSize: "large", fontWeight: "400" }}>
            The User represents and warrants that he/she is competent and
            eligible to enter into legally binding agreements and that he/she
            has the requisite authority to bind himself/herself to these Terms,
            as determined solely by the provisions of the Indian Contract Act,
            1872. The User may not use this Website if he/she is not competent
            to contract under the Indian Contract Act, 1872, or is disqualified
            from doing so by any other applicable law, rule or regulation
            currently in force.
          </p>
          <h4>4. TERM</h4>
          <p style={{ fontSize: "large", fontWeight: "400" }}>
            These Terms shall continue to form a valid and binding contract
            between the Parties, and shall continue to be in full force and
            effect until:
            <br /> a) The User continues to access and use the Website/Mobile
            Application; or <br />
            b) The Transaction between the Parties, if any, concludes to the
            satisfaction of both Parties; <br />
            c) You agree and acknowledge that http://blazify.tech control in any
            manner any transactions on the website. Accordingly, the contract of
            sale of services on the website/mobile application shall be a
            strictly bipartite contract between you and the Website
            http://blazify.tech.
          </p>
          <h4>5. FACILITIES TO BE RENDERED BY THE WEBSITE</h4>
          <p style={{ fontSize: "large", fontWeight: "400" }}>
            Blazify Private Limited through our website http://blazify.tech and
            our mobile application “Blazify” are a tech-based company that
            provides a platform for potential clients to hire professional from
            different fields like Mobile Development, Front-end & Back-end
            Development and to provide services like creating websites, desktops
            and mobile applications for day to day use by those who are unaware
            of technologiocal advancements prevailing in the tech arena. Users
            can select and hire the most appropriate professionals to complete
            their technological requirements at optimum value whereas
            professionals can get a better opportunity to scale up their
            business and share their experience and suggestions on any required
            projects and can provide their services to a large population. At
            Blazify we provide services through our online platform which will
            act as a mediator between professional from different fields and
            potential clients. Lastly, by using this Website, and providing
            his/her contact information to the company through the
            Website/application, the User hereby agrees and consents to receive
            calls, auto-dialled and/or pre-recorded message calls, e-mails and
            SMSs from the Company and/or any of its affiliates or partners at
            any time, subject to the Policy. If the User wishes to stop
            receiving any such marketing or promotional
            calls/email/messages/text messages, the User may send an e-mail to
            the effect admin@blazify.tech. The User agrees and acknowledges that
            it may take up to seven (7) business days for the Company to give
            effect to such a request by the User. The user Account on the
            Website through logging in by their third-party website user ID and
            password including that of www.facebook.com, websites owned by Yahoo
            Inc. or its subsidiaries, Google Inc. or its subsidiaries, Twitter
            or any other social media website or any other Internet service as
            permitted on the Website (User ID and/or other third party login
            identification as provided above are individually and collectively
            referred to the "Account Information").
          </p>
          <h4>6. FEE QUOTATION AND PAYMENT</h4>
          <p style={{ fontSize: "large", fontWeight: "400" }}>
            The quoted rates on Our Website for the Services are minimum rates
            determined on behalf of the eligibility of each professional
            executive on board, and they are liable to change as per the
            services availed by the user from time to time. The payment will be
            accepted through the payment gateway on the Website/ Mobile
            application (UPI, debit card, credit card, net banking, and in-hand
            cash) All of our service pricing is exclusive of taxes. The
            Commision will be charged at the rate of 1.5% on the final charges
            imposed on the availed services by the user along with the GST @ 18
            % will be charged at the time of billing as per prevailing
            government tax rates. All payments made via credit cards will be
            applicable for a 2% surcharge as charged by the banks towards the
            same and the same is to be borne by the User. Any additional service
            charge or fees (if applicable) will be mentioned on user’s final
            payment page of the order and will be relevantly charged during
            billing.
          </p>
          <h4>7. CANCELLATION AND TERMINATION POLICY</h4>
          <p style={{ fontSize: "large", fontWeight: "400" }}>
            a) The User does not have the right to cancel the Service after the
            order is confirmed and invoice is generated on the
            Website/Application with the client’s agreement and the No-Refund
            policy is followed by the company after the confirmation to the
            Company of the Service. If the user wishes to cancel the order
            without providing a reason of such cancellation then this may result
            into temporary/ permanent deactivation of the account of the User as
            per the terms of the Company. <br />
            b) Termination of the contract will not affect either party’s
            outstanding rights or duties, including Company’s right to recover
            any money owing to it under these terms and conditions.
          </p>
          <h4>8. TERMS, CONDITIONS AND DUTIES OF THE USER</h4>
          <p style={{ fontSize: "large", fontWeight: "400" }}>
            a) The User must provide one’s true/accurate/complete information,
            and all the details relating thereof on the website. If any User
            detail is found to be false then the Company shall reserve the right
            on its sole discretion to reject the registration and restrain the
            User from using the Services available on its website, without prior
            intimation whatsoever. <br />
            b) The User agrees to comply with all notices or instructions given
            by the Company from time to time to enable the use of the Services.{" "}
            <br />
            c) Users shall take care to choose the right services and are
            advised to check the service description carefully so the Company
            can deliver the right services.
            <br /> d) User shall indemnify and hold the Company, harmless for
            any loss or damage arising out of User’s to comply with any
            applicable laws or regulations and for breach of the following
            warranties and representations.
            <br /> e) The User shall at all times during the pendency of this
            agreement endeavour to protect and promote the interest of the
            Company and ensure that there will be no damage to a third party
            (client/customer) due to act/ omission on the part of the User.
            <br /> f) The user will be responsible for maintaining the
            confidentiality of the account and password for restricting access
            to your computer to prevent unauthorized access to the account.
            <br /> g) The user shall be responsible for all activities that
            occur under the user’s account.
            <br />
            <br />
            h) The User further undertakes not to:
            <br />
            <br /> i) Engage in any activity that interferes with or disrupts
            access to the Website or the services and/or goods provided therein
            (or the servers and networks which are connected to the Website);{" "}
            <br />
            ii) The User shall not share any personal contact number or email ID
            or any digital identification details to the professional executive
            under any circumstances; <br />
            iii) Probe, scan or test the vulnerability of the Website or any
            network connected to the Website, nor breach the security or
            authentication measures on the Website or any network connected to
            the Website; <br />
            iv) Disrupt or interfere with the security of, or otherwise cause
            harm to, the Website, systems resources, accounts, passwords,
            servers or networks connected to or accessible through the Websites
            or any affiliated or linked websites;
            <br /> v) Collect or store data about other Users of the Website.
            <br /> vi) Use the Website or any material or content therein for
            any purpose that is unlawful or prohibited by these Terms, or to
            solicit the performance of any illegal activity or other activity
            which infringes the rights of this Website or any other third party;
            <br /> vii) Violate any code of conduct or guideline which may be
            applicable for or to any particular service or product offered on
            the Website; <br />
            viii) Violate any portion of these Terms or the Policy, including
            but not limited to any applicable additional terms of the Website
            contained herein or elsewhere, whether made by amendment,
            modification, or otherwise;
            <br />
            ix) Commit any act that causes the Company to lose (in whole or in
            part) the services of its internet service provider ("ISP") or in
            any manner disrupts the services of any other supplier/service
            provider of the Company/Website;
            <br /> x) Engage in advertising to, or solicitation of, other Users
            of the Website to buy or sell any products or services not currently
            displayed on the Website. The User may not transmit any chain
            letters or unsolicited commercial or junk email/messages to other
            Users via the Website. It shall be a violation of these Terms to use
            any information obtained from the Website in order to harass, abuse,
            or harm another person, or to contact, advertise to, solicit, or
            sell to another User of the Website without the express prior
            written consent of the Company.
          </p>
          <h4>9. TERMS, CONDITIONS, AND DUTIES OF COMPANY</h4>
          <p style={{ fontSize: "large", fontWeight: "400" }}>
            a) The Company shall mention the entire list of services provided on
            the Website/mobile application in accordance with the policies.
            <br /> b) The Company acts as a mediator and is not responsible for
            any financial dispute arising between the professional executives.
            <br /> c) The Company shall be legally authorized to sell/hire the
            services(s) listed on the Website/mobile application and have all
            the necessary licenses and permits required for such sale. <br />
            d) The duty of the Company is not to mislead the description of any
            service/product or item and describe the actual condition of the
            same. <br />
            e) Company grants you a limited license to access and make personal
            use of this website, but not to download (other than page caching)
            or modify it, or any portion of it, except with express written
            consent of Company and/or its affiliates, as may be applicable.
            <br /> f) For any Payment settlement outside the Website/mobile
            application which causes any dispute, the Company will not be held
            responsible. <br />
            g) The Company collects financial data and asset data of customers
            to analyse the capacity of work that can be handled and the
            potential person can get work that is in his/her capacity. <br />
            h) The Company for identity verification will collect PAN no./ and
            Aadhar card no. Further approval for camera and media access from
            any customer to upload any kind of document or picture.
          </p>
          <h4>10. CUSTOMER RELATIONSHIP MANAGEMENT</h4>
          <p style={{ fontSize: "large", fontWeight: "400" }}>
            All issues, opinions, suggestions, questions and feedback while
            availing of our Services shall be communicated to us through the
            email address mentioned. Reporting of any issue needs to be within 7
            (seven) days of the happening of the issue, failing which, such
            issue will not be addressed. The Comapny shall endeavour to respond
            to your issues within 2 (two) working days of your reporting the
            same and endeavour to resolve it at the earliest possible. It is
            hereby clarified that issues are resolved on a severity basis, and
            certain may be resolved earlier than others. However, the Company
            shall not be liable for any damages or losses in the event you are
            not satisfied with any such resolution. By availing services on
            https://blazify.tech the customer acknowledges that the Company will
            Call/Email/SMS or send alerts to give/take information regarding
            his/her bookings. 11. SUSPENSION OF USER ACCESS AND ACTIVITY
            Notwithstanding other legal remedies that may be available to it,
            the Company may in its sole discretion limit the User’s access and/
            or activity by immediately removing the User’s access credentials
            either temporarily or indefinitely, or suspending/terminating the
            User’s membership, and/or refuse to provide User with access to the
            Website, without being required to provide the User with notice or
            cause:
            <br /> a) If the User is in breach of any of these Terms or the
            Policy; <br />
            b) If the User fails to provide any appropriate reason for
            cancellation of services availed or cancels service after the
            invoice is generated arbitrarily; <br />
            c) If the User has provided wrong, inaccurate, incomplete or
            incorrect information;
            <br /> d) If the User’s actions may cause any harm, damage or loss
            to the other Users or the Website/Company, at the sole discretion of
            the Company. <br />
            e) If the User’s action copying or duplicating in any manner any of
            content or other information available from the Website.
          </p>
          <h4>12. INDEMNITY AND LIMITATIONS</h4>
          <p style={{ fontSize: "large", fontWeight: "400" }}>
            The User hereby expressly agrees to defend, indemnify and hold
            harmless the Website and the Company, its directors, affiliate
            Users, employees, officers, agents and their successors and assigns
            and against any claims, liabilities, damages, losses, costs and
            expenses, including attorney's fees, caused by or arising out of
            claims based upon the User's actions or inactions, including but not
            limited to any warranties, representations or undertakings, or
            concerning the non-fulfilment of any of the User’s obligations under
            this Agreement, or arising out of the User's infringement of any
            applicable laws, rules and regulations, including but not limited to
            infringement of intellectual property rights, payment of statutory
            dues and taxes, claims of libel, defamation, violation of rights of
            privacy or publicity, loss of service by other subscribers, or the
            infringement of any other rights of a third party. In no event shall
            the Company/Website be liable to compensate the User or any third
            party for any special, incidental, indirect, consequential or
            punitive damages whatsoever, including those resulting from loss of
            use, data or profits, whether or not foreseeable, and whether or not
            the Company/Website had been advised of the possibility of such
            damages, or based on any theory of liability, including breach of
            contract or warranty, negligence or other tortuous action, or any
            other claim arising out of or in connection with the User’s use of
            or access to the Website and/or the services, products or materials
            contained therein.
          </p>
          <h4>13. INTELLECTUAL PROPERTY RIGHTS</h4>
          <p style={{ fontSize: "large", fontWeight: "400" }}>
            Unless expressly agreed to in writing, nothing contained herein
            shall give the User a right to use any of the Website’s trade names,
            trademarks, service marks, logos, domain names, information,
            questions, answers, solutions, reports, images, and other
            distinctive brand features, save according to the provisions of
            these Terms. All logos, trademarks, brand names, service marks, and
            domain names, including material, designs, and graphics created by
            and developed by the Website and other distinctive brand features of
            the Website are the property of the Company. Furthermore, for the
            Website created by the Company, the Company shall be the exclusive
            owner of all the designs, graphics and the like, related to the
            Website. The User is further aware that any reproduction or
            infringement of the intellectual property of the aforementioned
            owners by the User will result in legal action being initiated
            against the User by the respective owners of the intellectual
            property so reproduced/infringed upon. It is agreed to by the
            Parties that the contents of this clause shall survive even after
            the termination or expiry of the Terms and/or Policy.
          </p>
          <h4>14. DISCLAIMER OF WARRANTIES AND LIABILITIES</h4>
          <p style={{ fontSize: "large", fontWeight: "400" }}>
            a) Except as otherwise expressly stated on the Website, all
            products/services offered on the Website are offered on an "as is"
            basis without any warranty whatsoever, either express or implied.
            <br /> b) Information accessed through the Company website and other
            touch points is presented in summary form and is not exhaustive. The
            Company makes no warranties or representations as to its accuracy or
            completeness. This information is provided "as is" without warranty
            of any kind, either express or implied, including but not limited to
            implied warranties of merchantability, fitness for a particular
            purpose, or non-infringement.
            <br /> c) The Company/Website does not guarantee that the functions
            and services contained in the Website will be uninterrupted or
            error-free, or that the Website or its server will be free of
            viruses or other harmful components, and the User hereby expressly
            accepts any and all associated risks involved with the User’s use of
            the Website.
            <br /> d) It is further agreed to by the Parties that the contents
            of this Clause shall survive even after the termination or expiry of
            the Terms and/or Policy.
          </p>
          <h4>15. SUBMISSIONS</h4>
          <p style={{ fontSize: "large", fontWeight: "400" }}>
            Any comments, feedback, ideas, suggestions, initiation, or any other
            content contributed by the User to the Company or this Website will
            be deemed to include a royalty-free, perpetual, irrevocable,
            nonexclusive right and license for the Company to adopt, publish,
            reproduce, disseminate, transmit, distribute, copy, use, create
            derivative works, display worldwide, or act on such content, without
            additional approval or consideration, in any media, or technology
            now known or later developed, for the full term of any rights that
            may exist in such content, and the User hereby waives any claim to
            the contrary.
          </p>
          <h4>16. DISPUTE RESOLUTION AND JURISDICTION</h4>
          <p style={{ fontSize: "large", fontWeight: "400" }}>
            It is expressly agreed to by the Parties hereto that the formation,
            interpretation and performance of these Terms and any
            disputes/claims/refund/compensation arising here from will be
            resolved through a two-step Alternate Dispute Resolution (“ADR”)
            mechanism. It is further agreed to by the Parties that the contents
            of this Clause shall survive even after the termination or expiry of
            the Terms and/or Policy.
            <br /> a) Mediation: In case of any
            dispute/claim/refund/compensation between the parties, the Parties
            will attempt to resolve the same amicably amongst themselves, to the
            mutual satisfaction of both Parties. If the Parties are unable to
            reach such an amicable solution within thirty (30) days of one Party
            communicating the existence of a dispute to the other Party, the
            dispute will be resolved by arbitration, as detailed herein below.
            <br /> b) Arbitration. If the Parties are unable to amicably resolve
            a dispute by mediation, said dispute will be referred to arbitration
            under Arbitration and Conciliation Act, 1996 by a sole arbitrator to
            be appointed by the Company, and the award passed by such sole
            arbitrator will be valid and binding on both Parties. The Parties
            shall bear their costs for the proceedings, although the sole
            arbitrator may, in his/her sole discretion, direct either Party to
            bear the entire cost of the proceedings. The seat of Arbitration
            shall be in Berhampore, Murshidabad, West Bengal, India. <br />
            c) This Agreement shall be governed by and construed in accordance
            with the laws of India. The Parties hereby agree to submit to the
            jurisdiction of the competent Courts at Berhampore, Murshidabad,
            West Bengal, India.
          </p>
          <h4>17. NOTICES</h4>

          <p style={{ fontSize: "large", fontWeight: "400" }}>
            Any or all kinds of communication relating to any dispute or
            grievance experienced by the User may be communicated to the Company
            by the User reducing the same to writing, and sending the same to
            the registered office of the Company by Registered Post
            Acknowledgement Due / Speed Post Acknowledgement Due (RPAD / SPAD).
            18. MISCELLANEOUS PROVISIONS <br />
            a) Entire Agreement: These Terms, read with the Policy form the
            complete and final contract between the User and the Company with
            respect to the subject matter hereof and supersedes all other
            communications, representations and agreements (whether oral,
            written or otherwise) relating thereto.
            <br /> b) Waiver: The failure of either Party at any time to require
            the performance of any provision of these Terms shall in no manner
            affect such Party's right at a later time to enforce the same. No
            waiver by either Party of any breach of these Terms, whether by
            conduct or otherwise, in any one or more instances, shall be deemed
            to be or construed as a further or continuing waiver of any such
            breach, or a waiver of any other breach of these Terms. <br />
            c) Severability: If any provision/clause of these Terms is held to
            be invalid, illegal or unenforceable by any court or authority of
            competent jurisdiction, the validity, legality and enforceability of
            the remaining provisions/clauses of these Terms shall in no way be
            affected or impaired thereby, and each such provision /clause of
            these Terms shall be valid and enforceable to the fullest extent
            permitted by law. In such case, these Terms shall be reformed to the
            minimum extent necessary to correct any invalidity, illegality or
            unenforceability, while preserving to the maximum extent the
            original rights, intentions and commercial expectations of the
            Parties hereto, as expressed herein.
          </p>
          {/* </p> */}
        </div>
      </Box>
    </>
  );
};

export default TermsOfService;
